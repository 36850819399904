import ApiClient from "../../shared/api/ApiClient";
import {
  DOWNLOAD_POS_ADMIN_REPORT,
  DOWNLOAD_SPEED_LIMIT_REPORT,
  DOWNLOAD_TASK_STATUS_REPORT,
} from "../../shared/api/ApiEndPoint";

export const downloadTaskStatusReport = (plannedArrivalDate: string): Promise<any> =>
  ApiClient.get(DOWNLOAD_TASK_STATUS_REPORT(plannedArrivalDate), { responseType: "blob" }).then((res) => res);

export const downloadSpeedLimitReport = (plannedArrivalDate: string): Promise<any> =>
  ApiClient.get(DOWNLOAD_SPEED_LIMIT_REPORT(plannedArrivalDate), {
    responseType: "blob",
  }).then((res) => res);

export const downloadPOSAdminReport = (startDate: string, endDate: string): Promise<any> =>
  ApiClient.get(DOWNLOAD_POS_ADMIN_REPORT, {
    params: { start_date: startDate, end_date: endDate },
    responseType: "blob",
  }).then((res) => res);
